<template>
  <div class="block-wrapper">
    <a-form @submit.prevent="handleSubmit">
      <a-form-item label="Имя сотрудника">
        <a-input
          id="username"
          v-model="user.username"
          autocomplete="username"
          placeholder="Введите почту"
          required
          minlength="2"
        />
      </a-form-item>
      <a-form-item label="Имя">
        <a-input
          v-model="user.first_name"
          placeholder="Введите имя"
          required
          minlength="2"
        />
      </a-form-item>
      <a-form-item label="Фамилия">
        <a-input
          v-model="user.last_name"
          placeholder="Введите фамилию"
          required
          minlength="2"
        />
      </a-form-item>
      <a-form-item label="Пароль">
        <a-input-password
          id="new-password"
          v-model="user.password"
          autocomplete="new-password"
          placeholder="Введите пароль"
          required
          minlength="6"
        />
      </a-form-item>
      <a-form-item label="Подтверждение пароля">
        <a-input-password
          v-model="user.passwordConfirmation"
          autocomplete="new-password"
          required
          placeholder="Подтвердите пароль "
          minlength="6"
        />
      </a-form-item>
      <a-form-item label="Роли">
        <a-select
          v-model="user.roles"
          placeholder="Выберите роли сотрудника"
          required
          mode="multiple"
        >
          <a-select-option
            v-for="(role, index) in roles"
            :key="index"
            :value="role.id"
          >
            {{ role.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item :style="{ display: 'flex', justifyContent: 'flex-end' }">
        <a-button @click.prevent="$router.go(-1)">
          Назад
        </a-button>
        <a-button
          :style="{ marginLeft: '15px' }"
          type="primary"
          html-type="submit"
        >
          Создать
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import axios from '@/axios'
import { SAVE_USER } from '@/store/types'
import { showNotification } from '@/utils'

export default {
  data() {
    return {
      roles: [],
      user: {
        username: '',
        password: '',
        passwordConfirmation: '',
        first_name: '',
        last_name: '',
        roles: [],
      },
    }
  },
  created() {
    this.getRoles()
  },
  methods: {
    handleSubmit() {
      if (this.user.passwordConfirmation !== this.user.password) {
        return this.$message.error('Пароли не совпадают')
      } else {
        this.$store.dispatch(SAVE_USER, this.user)
      }
    },
    async getRoles() {
      try {
        const { data } = await axios.get('/roles')

        this.roles = data
      } catch (error) {
        showNotification(
          'Ошибка',
          error.response ? error.response.data.message : error.message,
          'error',
        )
      }
    },
  },
}
</script>
